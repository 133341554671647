<template>
  <v-container
    id="dashboard-view"
    fluid
    tag="section"
    class="pa-0"
  >
    <v-row>
      <v-col>
        <v-data-table
          :headers="headers"
          :items="items"
          :options.sync="options"
          :server-items-length="totalItems"
          class="elevation-1 "
          :loading-text="$t('table.messages.loading')"
          :loading="loading"
          :footer-props="{
            itemsPerPageOptions: [5, 10, 15, 30, 50],
            showFirstLastPage: false,
            'items-per-page-text': $t('table.messages.items_per_page'),
          }"
        >
          <template slot="no-data">
            {{ $t('table.messages.no_data') }}
          </template>
          <template
            v-slot:footer.page-text=""
            class="mr-0"
          >
            {{ $t('table.messages.page') }}
            {{ options.page }}
          </template>
          <template v-slot:top>
            <v-container class="mt-5">
              <v-row>
                <v-col class="pb-0">
                  <div>
                    <span class="forth--text text-h4 font-weight-bold ">{{
                      $t('messages.factoryNo')
                    }}</span>
                  </div>
                </v-col>
              </v-row>
              <v-row class="d-flex ">
                <v-col cols="10">
                  <div class="align-self-center mr-9">
                    <v-text-field
                      v-model="search"
                      name="pa-0 "
                      outlined
                    />
                  </div>
                </v-col>

                <v-col
                  cols="2"
                  class="d-flex align-end p-0"
                >
                  <div class=" mb-9">
                    <v-btn
                      class="h-100"
                      large
                      color="primary"
                      @click="onSearch"
                    >
                      <span class="pr-5 pl-5 font-weight-bold text-h5">
                        {{ $t('table.messages.search') }}</span>
                    </v-btn>
                  </div>
                </v-col>
              </v-row>
            </v-container>
          </template>
          <template v-slot:item.index="props">
            {{ getIndex(props.index) }}
          </template>
          <template v-slot:item.actions="{item}">
            <v-row
              align="center"
              justify="space-around"
            >
              <v-btn
                color="#C9F7F5"
                style="color:#1BC5BD"
                @click="getDetailRevenue(item)"
              >
                {{ $t('button.earning') }}
              </v-btn>
              <v-btn
                color="#EEE5FF"
                style="color:#8950FC"
                @click="getDetailSalary(item)"
              >
                {{ $t('button.salary') }}
              </v-btn>
            </v-row>
          </template>
          <template v-slot:footer.prepend />
        </v-data-table>
      </v-col>
    </v-row>
    <v-snackbar v-model="snackbar">
      {{ snackbarMessage }}

      <template v-slot:action="{ attrs }">
        <v-btn
          color="pink"
          text
          v-bind="attrs"
          @click="snackbar = false"
        >
          {{ $t('messages.close') }}
        </v-btn>
      </template>
    </v-snackbar>
  </v-container>
</template>

<script>
  import { get } from 'vuex-pathify'

  export default {
    name: 'RevenueManagementList',
    data () {
      return {
        snackbarMessage: '',
        snackbar: false,
        snackbarCloseInerval: null,
        options: {},
        headers: [
          {
            text: '',
            align: 'center',
            value: 'width',
            width: '16em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.index'),
            align: 'center',
            value: 'index',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.factoryNumber'),
            align: 'center',
            value: 'code',
            class: 'font-weight-bold',
            width: '10em',
            sortable: false,
          },
          {
            text: this.$t('table.headers.customerName'),
            align: 'center',
            value: 'name',
            width: '10em',
            class: 'font-weight-bold',
            sortable: false,
          },
          {
            text: '',
            sortable: false,
            width: '10em',
            value: 'actions',
          },
          {
            text: '',
            align: 'center',
            value: 'width',
            width: '20em',
            sortable: false,
          },
        ],
        editedIndex: -1,
        items: [],
        currentOptions: {},
        search: '',
        loading: false,
        totalItems: 1,
      }
    },
    computed: {
      ...get('customer', ['list', 'message', 'status', 'error', 'totalCount']),
    },
    watch: {
      options: {
        handler () {
          this.initialize()
        },
        deep: true,
      },
      search (value) {
        this.initialize(value)
      },
      status (value) {
        this.loading = value === 'loading'
      },
      list (value) {
        this.items = value.data.customers
      },
      error (value) {
        this.showSnackBar(value)
      },
      totalCount (value) {
        this.totalItems = value
      },
    },
    created () {
    },
    mounted () {
    },
    methods: {
      initialize (search = {}) {
        const tmpOptions = { ...this.options }
        tmpOptions.limit = tmpOptions.itemsPerPage
        tmpOptions.offset = (tmpOptions.page - 1) * tmpOptions.limit
        if (this.search) {
          if (!search) {
            tmpOptions.offset = 0
            this.options.page = 0
          }
          tmpOptions.offset = 0
          this.options.page = 1
          tmpOptions.keyword = search && search.replace(/\s\s+/g, ' ')
        }

        delete tmpOptions.page
        delete tmpOptions.groupDesc
        delete tmpOptions.groupBy
        delete tmpOptions.mustSort
        delete tmpOptions.multiSort
        delete tmpOptions.itemsPerPage
        delete tmpOptions.sortDesc
        delete tmpOptions.sortBy
        this.currentOptions = Object.assign({}, tmpOptions)
        this.$store.dispatch('customer/getCustomers', tmpOptions)
      },

      onSearch () {
        if (this.search) {
          this.initialize(this.search && this.search.replace(/\s\s+/g, ' '))
        }
      },
      showSnackBar (message) {
        if (message) {
          this.snackbarMessage = this.$t(message)
          this.snackbar = true
          if (this.snackbarCloseInerval) clearInterval(this.snackbarCloseInerval)
          this.snackbarCloseInerval = setTimeout(() => {
            this.snackbar = false
          }, 5000)
        }
      },
      getIndex (index) {
        return (this.options.page - 1) * this.options.itemsPerPage + 1 + index
      },
      getDetailRevenue (item) {
        this.$router.push('/revenue-management/' + item.id)
        const payload = {
          customerId: item.id,
          now: 1,
        }
        localStorage.setItem('dataSearch', JSON.stringify(payload))
      },
      getDetailSalary (item) {
        this.$router.push('/salary-management/' + item.id)
        const payload = {
          customerId: item.id,
          now: 1,
        }
        localStorage.setItem('dataSearch', JSON.stringify(payload))
      },
    },
  }
</script>
